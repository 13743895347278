<template>
  <div class="bg-page-dark h-100">
    <b-container
      class="bg-white border border-gray position-relative p-0 overflow-auto h-100"
    >
      <div
        v-if="!$apollo.loading"
        class="d-flex flex-column justify-content-between p-3 p-md-5"
      >
        <div class="text-center my-3 my-md-5">
          <b-img-lazy v-if="layer.image" :src="layer.image" />
        </div>

        <div class="text-center" v-html="layer.text"></div>
        <b-btn :to="target" btn-block variant="primary">
          {{ layer.textBtn }}
        </b-btn>
      </div>
      <loader v-else />
    </b-container>
  </div>
</template>
<script>
import LAYER_QUERY from "@/graphql/Layer.gql";
export default {
  apollo: {
    // Simple query that will update the 'hello' vue property
    layer: {
      query: LAYER_QUERY,
      skip: true,
      result({ data, loading, networkStatus }) {
        this.$store.commit("layer", data.layer);
        this.valid();
      },
    },
  },
  computed: {
    target: function () {
      return this.$store.state.target || "/";
    },
  },
  created() {
    if (this.$store.state.layer) {
      this.$apollo.queries.layer.skip = false;
      this.$apollo.queries.layer.refetch();
    } else {
      this.valid();
    }
  },
  methods: {
    valid() {
      if (this.$store.state.layer.name) {
        let layerName = "show_" + this.$store.state.layer.name;
        localStorage.setItem(layerName, 1);
      } else {
        this.$router.push(this.target);
      }
    },
  },
};
</script>
